import React from "react";
import { Image, Box } from "@madmeerkatfinance/uikit";
import { SpinnerProps } from "./types";

const Spinner: React.FC<React.PropsWithChildren<SpinnerProps>> = ({ size = 128 }) => {
  return (
    <Box width={size} height={size} position="relative">
      <Image width={size} height={size} src="https://mm.finance/madmeerkat/degen-logo.png" alt="MM Degen Logo" />
    </Box>
  );
};

export default Spinner;
